






















import moment, { ISO_8601 } from 'moment'
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: String,
    disabled: Boolean,
    dateDisabled: Boolean,
    allowedDates: Function
  },
  data() {
    return {
      date: null as string,
      time: null as string
    }
  },
  watch: {
    value: {
      handler(val: string) {
        this.date = val
        this.time = val || moment().hour(10).minute(0).toISOString()
      },
      immediate: true
    }
  },
  computed: {
    mergedDateTime(): string {
      const date = moment(this.date, ISO_8601, true)
      const time = moment(this.time, ISO_8601, true)

      if (date.isValid() && time.isValid()) {
        date.hour(time.hour())
        date.minute(time.minute())
        return date.toISOString()
      }
      return null
    }
  },
  methods: {
    emitMergedDateTime() {
      const mergedDateTime = this.mergedDateTime
      if (mergedDateTime) {
        this.$emit('input', mergedDateTime)
      }
    }
  }
})
