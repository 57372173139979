var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"cursor":"default"},attrs:{"items":_vm.value,"headers":_vm.headers,"hide-default-footer":"","dense":"","disable-pagination":"","must-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'SourceData', query: { id: item.sourceDataId } },"target":"_blank"}},[_vm._v(_vm._s(item.legalTitle || item.productName))])]}},{key:"item.supportStartDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.supportStartDate)))]}},{key:"item.supportEndDate1",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.supportEndDate1)))]}},{key:"item.supportEndDate2",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.supportEndDate2)))]}},{key:"item.supportEndDate3",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.supportEndDate3)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":_vm.disabled,"icon":"","small":"","title":"Unmap"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('unmap', item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$trash")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }