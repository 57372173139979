






import EventHighlightMixin from '../mixins/EventHighlightMixin'

export default EventHighlightMixin.extend({})
