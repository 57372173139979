















































































import AlertTypesMixin from '@/areas/alerts/mixins/AlertTypesMixin'
import Panel from '@/components/Panel.vue'
import ListingAlertDialog from './ListingAlertDialog.vue'
import ListingSelectDialog from './ListingSelectDialog.vue'
import Draggable from 'vuedraggable'
import EventHighlight from '@/areas/events/components/EventHighlight.vue'
import { PropType } from 'vue'
import ListingModel from '../models/ListingModel'
import ListingNoteModel from '../models/ListingNoteModel'

export default AlertTypesMixin.extend({
  components: {
    Panel,
    ListingAlertDialog,
    ListingSelectDialog,
    Draggable,
    EventHighlight
  },
  props: {
    disabled: Boolean,
    value: Object as PropType<ListingModel>
  },
  data() {
    return {
      activeItem: null,
      itemToCopy: null as ListingNoteModel
    }
  },
  methods: {
    onCopy(e) {
      this.$emit('copy', { ...this.itemToCopy }, e)
      this.itemToCopy = null
    },
    reorderAlerts(notes: ListingNoteModel[]) {
      const listing = this.$clone(this.value)
      notes.forEach((note, index) => {
        this.$set(note, 'sortOrder', index)
      })
      listing.listingNotes = notes
      this.$emit('input', listing)
    },
    getNoteTypeName(id) {
      return this.getAlertTypeById(id)?.name
    },
    addAlert() {
      this.activeItem = new ListingNoteModel()
    },
    saveAlert(alert: ListingNoteModel) {
      const listing = this.$clone(this.value)
      const alerts = listing.listingNotes
      const index = alerts.findIndex((x) => x.$_uuid === alert.$_uuid)

      if (index >= 0) {
        alerts.splice(index, 1, alert)
      } else {
        alert.sortOrder = alerts.length
        alerts.push(alert)
      }

      this.$emit('input', listing)
      this.activeItem = null
    },
    async deleteAlert(alert: ListingNoteModel) {
      if (
        await this.$confirm('Are you sure you want to delete the alert?', {
          title: 'Delete Alert?'
        })
      ) {
        const listing = this.$clone(this.value)
        const index = listing.listingNotes.findIndex(
          (x) => x.$_uuid === alert.$_uuid
        )
        listing.listingNotes.splice(index, 1)
        this.$emit('input', listing)
      }
    }
  }
})
