import EventColors from './EventColors'

export default class EventColorScheme {
  static sourceChangesReviewed = new EventColors('green lighten-3', 'green lighten-5')
  static publishToPreview = new EventColors('green green--text text--lighten-5', 'green lighten-4')
  static publishApproved = new EventColors('green darken-2 green--text text--lighten-5', 'green lighten-3')
  static publishToLive = new EventColors('green darken-4 green--text text--lighten-5', 'green lighten-3')
  static listingArchived = new EventColors('grey darken-4 grey--text text--lighten-5', 'grey lighten-3')
  static listingRestored = new EventColors('blue darken-4 blue--text text--lighten-5', 'blue lighten-3')
}
