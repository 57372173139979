





























import Vue, { PropType } from 'vue'
import Panel from '@/components/Panel.vue'
import ListingPublishStatus from './ListingPublishStatus.vue'
import ListingModel from '../models/ListingModel'
import ListingPublishStatusModel from '../models/ListingPublishStatusModel'
import { PublishStatusEnum } from '../models/PublishStatusEnum'

export default Vue.extend({
  components: {
    Panel,
    ListingPublishStatus
  },
  props: {
    value: Object as PropType<ListingModel>,
    disabled: Boolean
  },
  computed: {
    preview(): ListingPublishStatusModel {
      return this.value.listingPublishStatuses.find(
        (x) => x.environmentId === 1
      )
    },
    live(): ListingPublishStatusModel {
      return this.value.listingPublishStatuses.find(
        (x) => x.environmentId === 2
      )
    }
  },
  methods: {
    canPublish(item: ListingPublishStatusModel) {
      if (this.disabled) {
        return false
      }

      // Preview
      if (item.environmentId === 1) {
        return (
          this.value.reviewed && item.$_status !== PublishStatusEnum.Current
        )
      }

      // Live
      if (item.environmentId === 2) {
        return (
          this.preview.approved &&
          !this.preview.autoPublish &&
          this.preview.listingHash !== item.listingHash
        )
      }

      return false
    },
    showApproval(item: ListingPublishStatusModel) {
      if (this.disabled || item.environmentId !== 1) {
        return false
      }

      if (this.preview.listingHash !== this.live.listingHash) {
        return true
      }

      return false
    },
    showForm(item: ListingPublishStatusModel) {
      if (this.disabled || item.environmentId > 1) {
        return false
      }

      return true
    },
    onInput(item: ListingPublishStatusModel) {
      const listing = this.value
      const index = listing.listingPublishStatuses.findIndex(
        (x) => x.id === item.id
      )
      listing.listingPublishStatuses.splice(index, 1, item)
      this.$emit('input', listing)
    },
    onSourceDataReviewed(e) {
      const listing = { ...this.value }
      listing.reviewed = e
      this.$emit('input', listing)
    }
  }
})
