























































import Vue, { PropType } from 'vue'
import Panel from '@/components/Panel.vue'
import ReleasesTable from './ReleasesTable.vue'
import IListingRelease from '../models/IListingRelease'
import IListingEdition from '../models/IListingEdition'
import IListingReleaseDataSource from '../models/IListingReleaseDataSource'
import ListingModel from '../models/ListingModel'

export default Vue.extend({
  props: {
    value: Object as PropType<ListingModel>,
    disabled: Boolean
  },
  components: {
    Panel,
    ReleasesTable
  },
  data() {
    return {
      editionsToDelete: [] as IListingEdition[],
      releaseToDelete: null as IListingRelease,
      dataSourceToDelete: null as IListingReleaseDataSource
    }
  },
  methods: {
    addRelease() {
      const listing = this.$clone(this.value)

      listing.listingReleases.push({
        release: null,
        publish: false,
        isPrivate: false,
        supportStartDate: null,
        supportStartDateOverride: null,
        supportStartDateAttributeOverrideTypeId: 0,
        supportEndDate: null,
        supportEndDateOverride: null,
        supportEndDateAttributeOverrideTypeId: 0,
        listingReleaseDataSources: []
      })

      this.$emit('input', listing)
    },
    onDeleteRelease(release: IListingRelease) {
      this.releaseToDelete = release
      this.confirmDelete()
    },
    onDeleteDataSource(dataSource: IListingReleaseDataSource) {
      this.dataSourceToDelete = dataSource
      this.confirmDelete()
    },
    closeDeleteDialog() {
      this.editionsToDelete = []
      this.dataSourceToDelete = null
      this.releaseToDelete = null
    },
    deleteAndKeepLinkedEditions() {
      this.editionsToDelete = []
      this.delete()
    },
    deleteAndRemoveLinkedEditions() {
      this.delete()
    },
    async confirmDelete() {
      const editions = this.value.listingEditions
      const linkedEditions = [] as IListingEdition[]
      const editionsToDelete = [] as IListingEdition[]

      // gather data sources from releases to delete or individual data source to delete
      const dataSources = this.releaseToDelete?.listingReleaseDataSources || []
      if (this.dataSourceToDelete) {
        dataSources.push(this.dataSourceToDelete)
      }

      // find editions linked to each data source
      for (const dataSource of dataSources) {
        for (const editionDs of dataSource.listingEditionDataSources) {
          const edition = editions.find(
            (y) => y.id === editionDs.listingEditionId
          )
          if (edition) {
            linkedEditions.push(edition)
          }
        }
      }

      // find other data sources that are also using these editions
      for (const linkedEdition of linkedEditions) {
        const datasets = this.value.getDataSourcesByEditionId(linkedEdition.id)
        if (datasets.length === 1) {
          editionsToDelete.push(linkedEdition)
        }
      }

      if (editionsToDelete.length) {
        this.editionsToDelete = editionsToDelete
      } else if (
        await this.$confirm('Are you sure you want to unmap this record?', {
          title: 'Unmap Source Data?'
        })
      ) {
        this.delete()
      }
    },
    delete() {
      if (this.editionsToDelete.length) {
        this.$emit('delete-editions', this.editionsToDelete)
      }
      if (this.releaseToDelete) {
        this.$emit('delete-release', this.releaseToDelete)
      }
      if (this.dataSourceToDelete) {
        this.$emit('delete-datasource', this.dataSourceToDelete)
      }

      this.closeDeleteDialog()
    }
  }
})
