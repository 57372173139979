








































































import Alert from '@/components/Alert.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'
import FormMixin from '@/mixins/FormMixin'
import moment from 'moment'
import { PublishStatusEnum } from '../models/PublishStatusEnum'

export default FormMixin.extend({
  components: {
    Alert,
    DateTimePicker
  },
  props: {
    showApproval: Boolean,
    disabled: Boolean
  },
  computed: {
    publishDate(): string {
      return this.form.publishOnOverride || this.form.publishOn
    },
    canClearPublishDate(): boolean {
      return moment(this.publishDate) <= moment()
    },
    isCurrent(): boolean {
      return this.form.$_status === PublishStatusEnum.Current
    }
  },
  methods: {
    onPublishDateChange(date: string) {
      this.form.publishOnOverride = date
    },
    onTogglePublishDateOverride(value: boolean) {
      if (!value) {
        this.form.publishOnOverride = null
      }
    },
    onToggleApproved(value: boolean) {
      if (!value) {
        this.form.autoPublish = false
      }
    },
    allowedDates(val: any) {
      const currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - 3)
      return new Date(val) >= currentDate
    },
    onClearPublishDate() {
      if (moment(this.form.publishOnOverride) <= moment()) {
        this.form.publishOnOverride = null
        this.form.$_publishOnOverridden = false
      }

      if (moment(this.form.publishOn) <= moment()) {
        this.form.publishOn = null
      }
    }
  }
})
