






























import FormMixin from '@/mixins/FormMixin'

export default FormMixin.extend({})
