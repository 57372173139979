






























import Vue from 'vue'
import Panel from '@/components/Panel.vue'
import ListingContactDialog from './ListingContactDialog.vue'
import ListingContact from './ListingContact.vue'
import ListingContactModel from '../models/ListingContactModel'
import ListingModel from '../models/ListingModel'
import { groupBy } from 'lodash-es'

export default Vue.extend({
  components: {
    Panel,
    ListingContact,
    ListingContactDialog
  },
  data() {
    return {
      activeItem: null as ListingContactModel
    }
  },
  props: {
    value: null as () => ListingModel,
    disabled: Boolean
  },
  computed: {
    noData(): boolean {
      return !this.value?.listingContacts?.length
    },
    linkedContacts(): any {
      return groupBy(this.value.listingContacts, 'name')
    }
  },
  methods: {
    getIndex(contact: ListingContactModel) {
      const contacts = this.value.listingContacts as ListingContactModel[]
      return contacts.findIndex((x) => x.$_uuid === contact.$_uuid)
    },
    saveContact(contact: ListingContactModel) {
      const index = this.getIndex(contact)
      const listing = this.$clone(this.value)
      if (index < 0) {
        listing.listingContacts.push(contact)
      } else {
        listing.listingContacts.splice(index, 1, contact)
      }
      this.$emit('input', listing)
      this.activeItem = null
    },
    createContact() {
      this.activeItem = new ListingContactModel()
    },
    editContact(contact: ListingContactModel) {
      this.activeItem = this.$clone(contact)
    },
    async deleteContact(contact: ListingContactModel) {
      if (
        await this.$confirm(
          `Are you sure you want to delete the contact '${contact.name}'?`,
          {
            title: 'Delete Contact?',
            buttonTrueText: 'Delete',
            buttonTrueColor: 'error'
          }
        )
      ) {
        const index = this.getIndex(contact)
        const listing = this.$clone(this.value)
        listing.listingContacts.splice(index, 1)
        this.$emit('input', listing)
      }
    }
  }
})
