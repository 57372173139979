import ViewModel from '@/models/ViewModel'
import IListingComment from './IListingComment'

export default class ListingComment extends ViewModel implements IListingComment {
  id?: number
  uniqueId?: string
  modifiedDate = new Date().toISOString()
  modifiedBy: string
  createdBy?: string
  createdOn = new Date().toISOString()
  listingId: number
  important = false
  actionItem = false
  resolvedOn?: string
  resolvedBy?: string
  text = ''

  constructor(comment?: IListingComment) {
    super()
    Object.assign(this, comment)
  }
}
