



















































































import FormMixin from '@/mixins/FormMixin'

export default FormMixin.extend({
  props: {
    create: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      edit: false
    }
  },
  computed: {
    userName(): string {
      return this.$auth.getAccount().userName
    },
    canEdit(): boolean {
      return this.userName === this.form.modifiedBy
    },
    actionItemIconColor(): string {
      return this.form.actionItem ? 'primary' : undefined
    },
    importantIconColor(): string {
      return this.form.important ? 'red' : undefined
    }
  },
  methods: {
    onSubmit() {
      this.$emit('save', this.form)
      this.edit = false
    },
    onCancel() {
      this.edit = false
      this.form.text = this.value.text
      if (this.create) {
        this.form.text = null
      }
    },
    onImportantClick() {
      this.form.important = !this.form.important
      this.$emit('important-changed', this.form.important)
    },
    onActionItemClick() {
      this.form.actionItem = !this.form.actionItem
      this.$emit('action-item-changed', this.form.actionItem)
    }
  }
})
