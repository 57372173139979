import EventColorScheme from './EventColorScheme'
import ListingEventMapping from './ListingEventMapping'

const mappings = [
  new ListingEventMapping(/^displayName$/i, change => {
    change.description = 'Listing Name'
  }),
  new ListingEventMapping(/^slug$/i, change => {
    change.description = 'Listing Slug'
  }),
  new ListingEventMapping(/^supportPolicyId$/i, change => {
    change.description = 'Listing Lifecycle Policy'
  }),
  new ListingEventMapping(/^reviewed$/i, (change, event) => {
    if (change.newValue === true) {
      event.name = 'Source Data Changes Reviewed'
      event.colors = EventColorScheme.sourceChangesReviewed
    }
    change.description = 'Source Data Changes Reviewed'
  }),
  new ListingEventMapping(/^isRemoved$/i, (change, event) => {
    if (change.newValue === true) {
      event.name = 'Listing Archived'
      event.colors = EventColorScheme.listingArchived
    }
    if (change.newValue === false) {
      event.name = 'Listing Restored'
      event.colors = EventColorScheme.listingRestored
    }
    change.description = 'Listing Archived'
  }),
  new ListingEventMapping(/^parentId$/i, change => {
    change.description = 'Listing Product'
  }),
  new ListingEventMapping(/SupportDates\/[^/]*\/value$/i, change => {
    change.description = 'Listing "{{1}}"'
  }),
  new ListingEventMapping(/SupportDates\/[^/]*\/supportPolicyDateTypeId$/i, change => {
    change.description = 'Listing "{{1}}" Date Type'
  }),
  new ListingEventMapping(/SupportDates\/[^/]*\/attributeOverrideTypeId$/i, change => {
    change.description = 'Listing "{{1}}" Override Type'
  }),
  new ListingEventMapping(/SupportDates\/[^/]*\/overrideValue$/i, change => {
    change.description = 'Listing "{{1}}" Override Date'
  }),
  new ListingEventMapping(/PublishStatus\/[^/]*\/autoPublish$/i, change => {
    change.description = 'Auto Publish'
    change.hidden = true
  }),
  new ListingEventMapping(/PublishStatus\/[^/]*\/publishedDate$/i, change => {
    change.description = 'Published Date'
  }),
  new ListingEventMapping(/PublishedListings\/[^/]*\/publishedDate$/i, (change, event) => {
    if (/^Listing .* has been updated in 'Preview'$/i.test(change.description)) {
      event.name = 'Published to Preview'
      event.colors = EventColorScheme.publishToPreview
    }
    if (/^Listing .* has been updated in 'Live'$/i.test(change.description)) {
      event.name = 'Published to Live'
      event.colors = EventColorScheme.publishToLive
    }
    change.description = 'Published Date'
  }),
  new ListingEventMapping(/PublishedListings\/[^/]*$/i, (change, event) => {
    if (/^Listing .* has been published to 'Preview'$/i.test(change.description)) {
      event.name = 'Published to Preview'
      event.colors = EventColorScheme.publishToPreview
      return
    }
    if (/^Listing .* has been published to 'Live'$/i.test(change.description)) {
      event.name = 'Published to Live'
      event.colors = EventColorScheme.publishToLive
      return
    }
    if (/^Listing .* has been removed from 'Preview'$/i.test(change.description)) {
      event.name = 'Unpublished from Preview'
      return
    }
    if (/^Listing .* has been removed from 'Live'$/i.test(change.description)) {
      event.name = 'Unpublished from Live'
    }
  }),
  new ListingEventMapping(/(PublishedListings|PublishStatus)\/[^/]*\/approved$/i, (change, event) => {
    if (change.newValue === true) {
      event.name = 'Approved for Live'
      event.colors = EventColorScheme.publishApproved
    }
    change.description = 'Publish Approved'
  }),
  new ListingEventMapping(/^PublishStatus\/[^/]*/i, change => {
    if (change.action === 'Created') {
      change.hidden = true
    }
  }),
  new ListingEventMapping(/(PublishedListings|PublishStatus)\/[^/]*\/listingDescription$/i, change => {
    change.description = 'Published Listing Description'
  }),
  new ListingEventMapping(/(PublishedListings|PublishStatus)\/[^/]*\/approvers$/i, change => {
    change.description = 'Publish Approvers'
  }),
  new ListingEventMapping(/(PublishedListings|PublishStatus)\/[^/]*\/(publishOn|publishOnOverride)$/i, change => {
    change.hidden = true
  }),
  new ListingEventMapping(/(PublishedListings)\/[^/]*\/listingName$/i, change => {
    change.description = 'Published Listing Name'
  }),
  new ListingEventMapping(/(PublishedListings)\/[^/]*\/listingSlug$/i, change => {
    change.description = 'Published Listing Slug'
  }),
  new ListingEventMapping(/(PublishedListings)\/[^/]*\/parentId$/i, change => {
    change.description = 'Published Product Name'
  }),
  new ListingEventMapping(/(PublishedListings)\/[^/]*\/supportPolicyId$/i, change => {
    change.description = 'Publish Support Policy'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/releaseId$/i, change => {
    change.hidden = true
  }),
  new ListingEventMapping(/Releases\/[^/]*\/publish$/i, change => {
    change.description = 'Release "{{1}}" Publish checkbox'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/isPrivate$/i, change => {
    change.description = 'Release "{{1}}" Internal Only checkbox'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/supportEndDate$/i, change => {
    change.description = 'Release "{{1}}" End Date'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/supportEndDateAttributeOverrideTypeId$/i, change => {
    change.description = 'Release "{{1}}" End Date Override Type'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/supportEndDateOverride$/i, change => {
    change.description = 'Release "{{1}}" End Date Override'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/supportStartDate$/i, change => {
    change.description = 'Release "{{1}}" Start Date'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/supportStartDateAttributeOverrideTypeId$/i, change => {
    change.description = 'Release "{{1}}" Start Date Override Type'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/supportStartDateOverride$/i, change => {
    change.description = 'Release "{{1}}" Start Date Override'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/productOwner$/i, change => {
    change.description = 'Source Data "{{3}}" Product Owner on release "{{1}}"'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/pmmlead$/i, change => {
    change.description = 'Source Data "{{3}}" PMM Lead on release "{{1}}"'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/edition$/i, change => {
    change.description = 'Source Data "{{3}}" Edition on release "{{1}}"'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/version$/i, change => {
    change.hidden = true
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/displayName$/i, change => {
    change.description = 'Source Data Name on release "{{1}}"'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/legalTitle$/i, change => {
    change.description = 'Source Data Legal Title on release "{{1}}"'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/productName$/i, change => {
    change.description = 'Source Data Product Name on release "{{1}}"'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/SupportStartDate$/i, change => {
    change.description = 'Source Data "{{3}}" Start Date on release "{{1}}"'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/SupportEndDate1$/i, change => {
    change.description = 'Source Data "{{3}}" Mainstream/Retirement Date on release "{{1}}"'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/SupportEndDate2$/i, change => {
    change.description = 'Source Data "{{3}}" Extended Date on release "{{1}}"'
  }),
  new ListingEventMapping(/Releases\/[^/]*\/DataSources\/[^/]*\/SupportEndDate3$/i, change => {
    change.description = 'Source Data "{{3}}" Service Pack Date on release "{{1}}"'
  }),
  new ListingEventMapping(/Notes\/[^/]*\/text$/i, change => {
    change.description = 'Alert Text'
  }),
  new ListingEventMapping(/Notes\/[^/]*\/noteTypeId$/i, change => {
    change.description = 'Alert Type'
  }),
  new ListingEventMapping(/Notes\/[^/]*\/sortOrder$/i, change => {
    change.description = 'Alert "{{1}}" Sort Order'
  }),
  new ListingEventMapping(/Notes\/[^/]*\/id$/i, change => {
    change.hidden = true
  }),
  new ListingEventMapping(/Notes\/[^/]*\/listingId$/i, change => {
    change.hidden = true
  }),
  new ListingEventMapping(/Links\/[^/]*\/label$/i, change => {
    change.description = 'Link Label'
  }),
  new ListingEventMapping(/Links\/[^/]*\/url$/i, change => {
    change.description = 'Link URL'
  }),
  new ListingEventMapping(/Links\/[^/]*\/sortOrder$/i, change => {
    change.description = 'Link Sort Order'
  }),
  new ListingEventMapping(/^Contacts/i, change => { change.hidden = true }),
  new ListingEventMapping(/^Comments/i, change => { change.hidden = true }),
  new ListingEventMapping(/concurrency$/i, change => { change.hidden = true }),
  new ListingEventMapping(/publishHash$/i, change => { change.hidden = true }),
  new ListingEventMapping(/listingHash$/i, change => { change.hidden = true }),
  new ListingEventMapping(/uniqueId$/i, change => { change.hidden = true }),
  new ListingEventMapping(/modifiedBy$/i, change => { change.hidden = true }),
  new ListingEventMapping(/modifiedDate$/i, change => { change.hidden = true }),
  new ListingEventMapping(/commitComment$/i, change => { change.hidden = true }),
  new ListingEventMapping(/publishedBy$/i, change => { change.hidden = true }),
  new ListingEventMapping(/dataChangeHash$/i, change => { change.hidden = true })
]

export default mappings
