var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',[_c('v-data-table',_vm._b({attrs:{"items":_vm.value,"headers":_vm.headers,"hide-default-footer":"","disable-pagination":"","must-sort":"","expanded":_vm.expanded,"single-expand":"","options":_vm.options,"custom-sort":_vm.onSort},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.release",fn:function(ref){
var item = ref.item;
return [_c('ReleaseSelect',{attrs:{"label":null,"disabled":_vm.disabled,"rules":"required","path":("Releases/" + (item.uniqueId) + "/releaseId")},model:{value:(item.releaseId),callback:function ($$v) {_vm.$set(item, "releaseId", $$v)},expression:"item.releaseId"}})]}},{key:"item.publish",fn:function(ref){
var item = ref.item;
return [_c('Checkbox',{staticClass:"mx-0 pt-4 pl-3",attrs:{"disabled":_vm.disabled,"dense":"","path":("Releases/" + (item.uniqueId) + "/publish")},on:{"change":function($event){item.isPrivate = false}},model:{value:(item.publish),callback:function ($$v) {_vm.$set(item, "publish", $$v)},expression:"item.publish"}})]}},{key:"item.isPrivate",fn:function(ref){
var item = ref.item;
return [_c('Checkbox',{staticClass:"mx-0 pt-4 pl-3",attrs:{"disabled":_vm.disabled,"dense":"","path":("Releases/" + (item.uniqueId) + "/isPrivate")},on:{"change":function($event){item.publish = false}},model:{value:(item.isPrivate),callback:function ($$v) {_vm.$set(item, "isPrivate", $$v)},expression:"item.isPrivate"}})]}},{key:"item.supportStartDate",fn:function(ref){
var item = ref.item;
return [_c('OverrideDatePicker',{attrs:{"offset-x":"","offset-y":"","left":"","top":"","nudge-left":"20","no-override":!item.listingReleaseDataSources.length,"date":item.supportStartDate,"overrideTypeId":item.supportStartDateAttributeOverrideTypeId,"overrideDate":item.supportStartDateOverride,"path":("Releases/" + (item.uniqueId) + "/supportStartDate"),"disabled":_vm.disabled},on:{"input":function($event){return _vm.setStartDate(item, $event)}}})]}},{key:"item.supportEndDate",fn:function(ref){
var item = ref.item;
return [_c('OverrideDatePicker',{attrs:{"offset-x":"","offset-y":"","right":"","top":"","nudge-left":"20","no-override":!item.listingReleaseDataSources.length,"date":item.supportEndDate,"overrideTypeId":item.supportEndDateAttributeOverrideTypeId,"overrideDate":item.supportEndDateOverride,"path":("Releases/" + (item.uniqueId) + "/supportEndDate"),"disabled":_vm.disabled},on:{"input":function($event){return _vm.setEndDate(item, $event)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":_vm.disabled || _vm.value.length < 2,"icon":"","small":"","title":"Delete"},on:{"click":function($event){return _vm.$emit('delete-release', item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$trash")])],1),_c('v-btn',{staticClass:"ml-4",attrs:{"disabled":!item.listingReleaseDataSources.length,"icon":"","small":"","title":"Expand"},on:{"click":function($event){return _vm.expandItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$expand")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-5",attrs:{"colspan":headers.length}},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(item.displayName)+" Source Data")]),_c('ReleaseDataSourcesTable',{attrs:{"value":item.listingReleaseDataSources,"support-policy-id":_vm.supportPolicyId,"disabled":_vm.disabled},on:{"unmap":function($event){return _vm.$emit('delete-datasource', $event)}}})],1)]}}],null,true)},'v-data-table',_vm.$attrs,false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }