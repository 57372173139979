




































import OverrideTypesSelect from '@/areas/overrides/components/OverrideTypesSelect.vue'
import OverrideTypesMixin from '../mixins/OverrideTypesMixin'

export default OverrideTypesMixin.extend({
  props: {
    label: String,
    date: String,
    overrideDate: String,
    overrideTypeId: Number,
    noOverride: Boolean
  },
  components: {
    OverrideTypesSelect
  },
  computed: {
    formattedDate(): string {
      return this.$formatter.date(this.date)
    },
    overrideTooltip(): string {
      const name = this.getOverrideTypeById(this.overrideTypeId)?.name
      return `${name}\nOriginal Date: ${this.formattedDate}`
    }
  },
  methods: {
    closePicker() {
      const picker = this.$refs.picker as any
      picker.showPicker = false
    },
    onNoDate() {
      this.$emit('input', {
        overrideTypeId: this.overrideTypeId,
        date: this.date,
        overrideDate: null
      })
      this.closePicker()
    },
    onOverrideChange(overrideTypeId: number) {
      let overrideDate = this.overrideDate

      if (overrideTypeId === 0) {
        overrideDate = null
      }

      this.$emit('input', {
        overrideTypeId,
        date: this.date,
        overrideDate
      })
    },
    onDateChange(newDate) {
      let date = this.date
      let overrideDate = newDate
      let overrideTypeId = this.overrideTypeId || 1

      if (this.noOverride) {
        overrideTypeId = 0
        overrideDate = null
        date = newDate
      }

      this.$emit('input', {
        overrideTypeId,
        date,
        overrideDate
      })

      this.closePicker()
    }
  }
})
