import ViewModel from '@/models/ViewModel'
import Formatter from '@/utils/Formatter'
import ListingEventMappings from './ListingEventMappings'
import ListingEvent from './ListingEvent'
import IEventChange from './IEventChange'

export default class ListingEventChange extends ViewModel implements IEventChange {
  action: string
  description: string
  hidden = false
  modifiedDate: string
  namedSubject: string
  newValue: any
  newValueDisplay: any
  oldValue: any
  oldValueDisplay: any
  subject: string
  hasMapping = false
  format: string = 'auto'

  get oldValueFormatted() {
    let value = ''
    // use the display value if there is something in either "display" field
    if (this.oldValueDisplay || this.newValueDisplay) {
      value = this.oldValueDisplay
    } else {
      value = this.oldValue
    }
    return Formatter.format(value, this.format) || 'null'
  }

  get newValueFormatted() {
    let value = ''
    // use the display value if there is something in either "display" field
    if (this.oldValueDisplay || this.newValueDisplay) {
      value = this.newValueDisplay
    } else {
      value = this.newValue
    }
    return Formatter.format(value, this.format) || 'null'
  }

  /** Replace placeholders in description with value of named subject segment index. */
  private updateDescription() {
    const segments = this.namedSubject.split('/')
    segments.forEach((segment, i) => {
      this.description = this.description.replace(`{{${i}}}`, segment)
    })
    if (this.action === 'Updated') {
      this.description = `${this.description} has been updated.`
    }
  }

  applyMapping(event: ListingEvent) {
    const mappings = ListingEventMappings.filter(x => x.regex.test(this.subject))
    for (const mapping of mappings) {
      this.hasMapping = true
      mapping.apply(this, event)
      this.updateDescription()
    }

    // hide action if not visible
    const visibleActions = ['Created', 'Deleted', 'Updated']
    if (!visibleActions.includes(this.action)) {
      this.hidden = true
    }
  }

  constructor(change: IEventChange) {
    super()
    Object.assign(this, change)
  }
}
