







































































import FormMixin from '@/mixins/FormMixin'
import Panel from '@/components/Panel.vue'
import EditionSelect from '@/areas/editions/components/EditionSelect.vue'
import IListingEdition from '../models/IListingEdition'
import IEdition from '@/areas/editions/models/IEdition'
import ListingModel from '../models/ListingModel'

export default FormMixin.extend({
  components: {
    Panel,
    EditionSelect
  },
  props: {
    disabled: Boolean
  },
  data() {
    return {
      showEditionDialog: false,
      editionIdToAdd: 0,
      editionIndexToDelete: -1,
      releaseSourceDataToUnmap: null
    }
  },
  computed: {
    noData(): boolean {
      const editions = this.form.listingEditions
      return !editions || !editions.length
    },
    orphanedEditions(): IListingEdition[] {
      const listing = this.value as ListingModel
      if (!listing) {
        return []
      }

      const releases = listing.listingReleases
      const editions = this.$store.getters['Editions/editions'] as IEdition[]
      const listingEditionIds = []

      releases.forEach((release) => {
        release.listingReleaseDataSources.forEach((releaseDs) => {
          releaseDs.listingEditionDataSources.forEach((editionDs) => {
            listingEditionIds.push(editionDs.listingEditionId)
          })
        })
      })

      const listingEditions = this.$clone(listing.listingEditions)

      return listingEditions
        .filter((le) => !listingEditionIds.includes(le.id))
        .map((le) => {
          le.edition = editions.find((edition) => edition.id === le.editionId)
          return le
        })
    }
  },
  methods: {
    getEditionName(id: number): string {
      return this.$store.getters['Editions/getEditionName'](id)
    },
    async onDeleteEdition(listingEdition, index) {
      this.editionIndexToDelete = index
      const dataSources = this.value.getDataSourcesByEditionId(
        listingEdition.id
      )
      if (dataSources.length) {
        this.releaseSourceDataToUnmap = dataSources
      } else if (
        await this.$confirm('Are you sure you want to delete this edition?', {
          title: 'Delete Edition?'
        })
      ) {
        this.form.listingEditions.splice(index, 1)
        this.editionIndexToDelete = -1
      }
    },
    getColor(listingEdition) {
      return this.isOrphan(listingEdition) ? 'warning darken-1' : undefined
    },
    getTooltip(listingEdition): string {
      const dataSources = this.value.getDataSourcesByEditionId(
        listingEdition.id
      )
      if (!dataSources.length) {
        return 'This edition is not linked to source data.'
      }

      let tooltip = 'Linked to the following source data:\n'

      dataSources.forEach((ds) => {
        tooltip += `-${ds.displayName}\n`
      })

      return tooltip
    },
    isOrphan(listingEdition: IListingEdition): boolean {
      const orphanedEditions = this.orphanedEditions as IListingEdition[]
      return orphanedEditions.some(
        (x) => x.editionId === listingEdition.editionId
      )
    },
    onCancel() {
      this.releaseSourceDataToUnmap = null
      this.editionIndexToDelete = -1
    },
    onUnmapSourceData() {
      this.$emit('delete-datasources', this.releaseSourceDataToUnmap)
      this.form.listingEditions.splice(this.editionIndexToDelete, 1)
      this.releaseSourceDataToUnmap = null
      this.editionIndexToDelete = -1
    },
    onKeepSourceData() {
      this.form.listingEditions.splice(this.editionIndexToDelete, 1)
      this.releaseSourceDataToUnmap = null
      this.editionIndexToDelete = -1
    },
    async onAddEdition() {
      const listingEdition: IListingEdition = {
        listingId: this.form.id,
        editionId: this.editionIdToAdd
      }
      this.form.listingEditions.push(listingEdition)

      this.editionIdToAdd = 0
      this.showEditionDialog = false
    },
    editionFilter(item: IEdition) {
      const listingEditions = this.form.listingEditions as IListingEdition[]
      return !listingEditions.some((x) => x.editionId === item.id)
    }
  }
})
