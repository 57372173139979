<template>
  <InfiniteLoading v-bind="$attrs" v-on="$listeners">
    <span slot="no-more"></span>
    <span slot="no-results"></span>
    <span slot="error"></span>
  </InfiniteLoading>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    InfiniteLoading
  }
}
</script>
