

















import Vue from 'vue'

export default Vue.extend({
  props: {
    title: String,
    icon: String,
    color: {
      type: String,
      default: 'grey lighten-3'
    }
  }
})
