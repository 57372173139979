import ODataMixin from '@/mixins/ODataMixin'
import FilterModel from '@/models/FilterModel'
import ODataResult from '@/models/ODataResult'
import IInfiniteLoadEvent from '@/models/IInfiniteLoadEvent'
import IODataQuery from '@/models/IODataQuery'
import ListingComment from '../models/ListingComment'
import IListingComment from '../models/IListingComment'

export default ODataMixin.extend({
  props: {
    listingId: {
      type: [Number],
      required: true
    }
  },
  data() {
    return {
      loadingComments: false,
      comments: new ODataResult<ListingComment>(),
      comment: null,
      filters: [
        new FilterModel('All', { active: true, filter: `listingId eq ${this.listingId}` }),
        new FilterModel('Important', { icon: '$important', iconColor: 'red', filter: `listingId eq ${this.listingId} and important eq true` }),
        new FilterModel('Actions', { icon: '$flag', iconColor: 'blue', filter: `listingId eq ${this.listingId} and actionItem eq true` })
      ] as FilterModel[]
    }
  },
  methods: {
    async getComments(query: IODataQuery = {}) {
      try {
        this.loadingComments = true
        query.$filter = this.filters.find(filter => filter.active)?.filter
        query.$orderby = 'modifiedDate desc'
        query.$count = true
        const result = await this.get<ListingComment>('ListingComments', query)
        result.value = result.value.map(x => new ListingComment(x))
        this.comments = result
      } finally {
        this.loadingComments = false
      }
    },
    async getMoreComments(e: IInfiniteLoadEvent) {
      this.getMore(this.comments, e, item => new ListingComment(item))
    },
    async saveComment(comment: IListingComment) {
      if (!comment.id) {
        comment.listingId = this.listingId
        await this.post('ListingComments', comment)
        await this.getComments()
        this.initNewComment()
      } else {
        await this.put(`ListingComments(${comment.id})`, comment)
      }
      await this.getFilterCounts()
    },
    async updateImportant(id: number, value: boolean) {
      await this.patch(`ListingComments(${id})`, { important: value })
      await this.getFilterCounts()
    },
    async updateActionItem(id: number, value: boolean) {
      const comment: Partial<ListingComment> = {
        actionItem: value,
        resolvedBy: null,
        resolvedOn: null
      }
      if (!value) {
        comment.resolvedBy = this.$auth.getAccount().userName
        comment.resolvedOn = new Date().toISOString()
      }
      await this.patch(`ListingComments(${id})`, comment)
      await this.getFilterCounts()
    },
    async getFilterCounts() {
      for (const filter of this.filters) {
        filter.count = await this.getCount('ListingComments', { $filter: filter.filter })
      }
    },
    async applyFilter(filter: FilterModel) {
      for (const item of this.filters) {
        item.active = (item.$_uuid === filter.$_uuid)
      }
      await this.getComments()
    },
    initNewComment() {
      const user = this.$auth.getAccount().userName
      const comment = new ListingComment()
      comment.listingId = this.listingId
      comment.modifiedBy = user
      comment.createdBy = user
      this.comment = comment
    }
  },
  created() {
    this.initNewComment()
    this.getFilterCounts()
  }
})
