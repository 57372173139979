


















import Vue from 'vue'
import EventItem from '@/areas/events/components/EventItem.vue'
import ListingEventChange from '../models/ListingEventChange'

export default Vue.extend({
  components: {
    EventItem
  },
  props: {
    change: Object as () => ListingEventChange,
    color: {
      type: String,
      default: 'grey lighten-3'
    }
  },
  computed: {
    updated(): boolean {
      return this.change.action === 'Updated'
    },
    description(): string {
      if (this.updated) {
        return this.change.description
      }

      return this.change.description
    },
    icon(): string {
      switch (this.change.action) {
        case 'Created':
          return '$add'
        case 'Updated':
          return '$edit'
        case 'Deleted':
          return '$trash'
        default:
          return null
      }
    }
  }
})
