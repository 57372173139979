











































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { orderBy } from 'lodash-es'
import IRelease from '../models/IRelease'

const { mapActions, mapGetters } = createNamespacedHelpers('Releases')

export default Vue.extend({
  props: {
    value: [String, Number],
    disabled: Boolean,
    label: {
      type: String,
      default: 'Release'
    }
  },
  data() {
    return {
      newReleaseName: null as string,
      showOverlay: false
    }
  },
  computed: {
    ...mapGetters(['releases', 'loading', 'error']),
    items(): IRelease[] {
      return orderBy(this.releases, 'displayName') || []
    },
    tooltip(): string {
      const releases = this.releases as IRelease[]
      if (!this.releases) {
        return
      }
      const selectedRelease = releases.find(
        (release) => release.id === this.value
      )
      return selectedRelease?.displayName
    }
  },
  methods: {
    ...mapActions({
      saveRelease: 'saveRelease'
    }),
    onCancel() {
      this.showOverlay = false
    },
    async onSubmit() {
      const newRelease: IRelease = {
        displayName: this.newReleaseName
      }

      const created: IRelease = await this.saveRelease(newRelease)
      this.$emit('input', created.id)
      this.showOverlay = false
    }
  }
})
