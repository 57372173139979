



























import Vue from 'vue'
import ListingsTable from '@/areas/listings/components/ListingsTable.vue'
import IListingFlat from '@/areas/listings/models/IListingFlat'

export default Vue.extend({
  components: {
    ListingsTable
  },
  props: {
    value: Object,
    title: String,
    description: String,
    icon: String,
    filter: String,
    submitText: {
      type: String,
      default: 'Ok'
    }
  },
  data() {
    return {
      selected: [] as IListingFlat[]
    }
  },
  watch: {},
  computed: {},
  methods: {
    onCancel() {
      this.$emit('cancel')
      this.selected = []
    },
    onSubmit() {
      this.$emit('submit', [...this.selected])
      this.selected = []
    }
  }
})
