






import Vue from 'vue'
import ListingContactForm from './ListingContactForm.vue'
import IListingContact from '../models/IListingContact'

export default Vue.extend({
  components: {
    ListingContactForm
  },
  props: {
    value: Object as () => IListingContact
  },
  data() {
    return {
      form: null
    }
  },
  computed: {
    title(): string {
      return this.value?.id ? 'Edit Contact' : 'Create Contact'
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onSubmit() {
      this.$emit('submit', this.form || this.value)
    }
  }
})
