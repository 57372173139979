






























































import Vue from 'vue'
import ListingContactModel from '../models/ListingContactModel'
import { groupBy } from 'lodash-es'
import ListingContactRoleModel from '../models/ListingContactRoleModel'
import { Dictionary } from 'vue-router/types/router'

export default Vue.extend({
  props: {
    value: Object as () => ListingContactModel,
    disabled: Boolean
  },
  computed: {
    rolesByName(): Dictionary<ListingContactRoleModel[]> {
      return groupBy(this.value.listingContactRoles, 'name')
    }
  }
})
